import { useMutation, useQuery } from "@tanstack/react-query";
import httpClient from "../../../api/httpClient";
import { isNil } from "lodash";

export const useUploadBulkUploadAPI = () => {
  const { data, isloading, mutate, isError } = useMutation((payload) => {
    return httpClient(`claim-Stage-History/bulk_upload`, {
      method: "POST",
      data: payload,
    });
  });
  return { mutate, data, isloading, isError };
};

export const useDownLoadBulkUploadSampleFile = () => {
  const lobId = new URLSearchParams(window.location.search).get("lobId");

  const { data } = useQuery(
    ["sampleFileForCommonBulkUpload", lobId],
    () => httpClient(`claim-Stage-History/export/${lobId}`),
    {
      enabled: Boolean(lobId),
    }
    // refetchOnMount: false,
    // refetchOnReconnect: false,
  );
  return { data };
};
