import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import {
  useCheckIfPolicyIsPresent,
  useCheckIfPolicyIsPresentOrNot,
  useCreateFinalSubmit,
  useCreateProposalData,
  useCreateProposalMongoId,
  useGetBrokerageCalculate,
  useGetFuelData,
  useGetManufacturerData,
  useGetModelData,
  useGetProposalDataById,
  useGetRestructuredBulkUploadProposalData,
  useGetRewardCalculate,
  useGetTransactionType,
  useGetVersionData,
  useGetVersionDetails,
  useUpdateExistingProposalData,
  useUpdateFinalSubmit,
} from "./service";

import {
  CONTRACTOR_DETAILS,
  EMPLOYEE_DETAILS,
  EXPENSE_DETAILS,
  PREVIOUS_POLICY_DETAILS,
} from "../constants";
import { useGetMasterTableDataFormWise } from "../../../hooks/useGetMasterTableData";
import {
  resetAllData,
  setPolicyViewCards,
  setProposalEditValuesAction,
  setProposalErrorAction,
  setProposalPartialUpdateSuccess,
  setProposalSuccessAction,
} from "../proposal.slice";
import { useGetLobById } from "../../../hooks/useGetLOBMaster";
import useGetClaimIntimationFieldData from "../../../hooks/useGetClaimIntimationFieldData";

function useClaimSummary() {
  const [userId, setUserId] = useState();
  const location = useLocation();
  const [updatedAt, setUpdatedAt] = useState("");

  const pathName = location.pathname;

  const isProposalSummaryRoute = pathName === "/view-claim";

  const dispatch = useDispatch();

  const { proposalError, proposalSuccess, proposalPartialUpdateSuccess } =
    useSelector((state) => state.proposalConfig);
  const navigate = useNavigate();

  const [showEditButton, setShowEditButton] = useState(true);
  const [editCard, setEditCard] = useState({});
  const [activeStep, setActiveStep] = useState(1); // active step decides which is the current active step(shrink/ full mode)
  const [completed, setCompleted] = useState({}); // stores which all steps are completed(along with validated fields)
  const [current, setCurrent] = useState({ 0: true }); //current step in focus
  const [isFirstView, setIsFirstView] = useState({ 0: true }); //is the current form opened for first time?
  const [mergedSchema, setMergedSchema] = useState([]); // dynamic yup schema
  const [currentFormMasterSlugs, setCurrentFormMasterSlugs] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0); // sets index of current card
  const [submittedAt, setSubmittedAt] = useState(""); // sets new value at the time of submit
  //sets members for multiple card addition
  const [members, setMembers] = useState({
    members: 1,
    payment: 1,
    employee: 1,
    nominee: 1,
    expenseDetails: 1,
    lossItemDetails: 1,
    claimantsDetails: 1,
    travelDetails: 1,
  });

  console.log(activeStep, "activeStep");
  const [goBackFromSummary, setGoBackFromSummary] = useState(false);

  const cardsRef = useRef(null);
  const buttonRef = useRef(null);

  const handleEditButton = () => {
    setShowEditButton(false);
  };

  const queryParams = new URLSearchParams(location.search);

  const lobId = queryParams.get("lob");
  const prefillDataMongoID = queryParams.get("mongoId");
  const mongoId = queryParams.get("mongoId");

  const transactionIdParams = queryParams.get("TransactionId");

  const { data: lobData } = useGetLobById(lobId);

  const isHealthLob = lobData?.data?.data?.lob === "Health";
  const lobName = lobData?.data?.data?.lob;

  const { data, isLoading, isError } = useGetClaimIntimationFieldData(lobId);

  const { mutate } = useCreateProposalData(mongoId);

  const {
    mutate: generateMongoId,
    isLoading: isCreateProposalDataLoading,
    isError: err,
  } = useCreateProposalMongoId();

  const {
    mutate: updateExistingData,
    isLoading: isUpdateExistingProposalDataLoading,
    isError: isErr,
  } = useUpdateExistingProposalData();

  const { mutate: createFinalSubmit, isLoading: isCreateFinalSubmitLoading } =
    useCreateFinalSubmit(mongoId);

  const {
    mutate: updateFinalSubmit,
    isLoading: isUpdateFinalSubmitLoading,
    data: proposalUpdate,
  } = useUpdateFinalSubmit();
  // const isUpdate = location.pathname === "/edit";

  const { data: manufacturerData } = useGetManufacturerData(lobId);
  const { data: PolicyType } = useGetTransactionType(lobId);
  const { data: modelData, mutate: getModalData } = useGetModelData();
  const { data: versionData, mutate: getVersionData } = useGetVersionData();
  const { data: fuelData, mutate: getFuelData } = useGetFuelData();
  const { data: mmvData, mutate: getMmvData } = useGetVersionDetails();
  const payloadObject = {
    lobId,
    transactionIdParams,
  };
  const { data: policyPresent } = useCheckIfPolicyIsPresentOrNot(payloadObject);

  const policyDetails = policyPresent?.data?.[0];
  const { data: prevPolicyPresent, mutate: checkPrevPolicyPresent } =
    useCheckIfPolicyIsPresent();

  const {
    data: proposalData,
    isError: errorTransacMongo,
    isLoading: isProposalDataLoading,
    isSuccess: isProposalDataSuccess,
  } = useGetProposalDataById(mongoId);

  const currentStepTitle = proposalData?.data?.data?.current_step;
  const sequenceData = data?.data?.category_sequence?.map(
    (value) => value && Object.values(value)[0]
  );

  const matchedObject =
    (data?.success &&
      _.zipObject(
        sequenceData,
        sequenceData.map((key) => data?.data?.data[key])
      )) ||
    {};

  const summaryData = proposalData?.data?.data?.data || {};

  const current_step = proposalData?.data?.data?.current_step;
  const expenseData = proposalData?.data?.data?.data?.expenses;

  const extraCardTitles = (data?.success && Object.keys(matchedObject)) || [];

  const prevPolicyCardIndex = extraCardTitles?.findIndex(
    (value) => value === EXPENSE_DETAILS
  );

  const contractorDetailsExists = matchedObject[CONTRACTOR_DETAILS];

  const contractorDetailsCardIndex = extraCardTitles?.findIndex(
    (value) => value === CONTRACTOR_DETAILS
  );

  const isPrevPolicyFromApi = summaryData?.expenseDetailss === "Yes";

  const insureYourLiabilityToContractor =
    summaryData[EMPLOYEE_DETAILS] &&
    summaryData[EMPLOYEE_DETAILS]?.members?.[0]?.compensationAct;

  const cardTitlesPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? extraCardTitles?.filter(
          (_, index) => index !== contractorDetailsCardIndex
        )
      : !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex)
      : isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraCardTitles
      : extraCardTitles?.filter(
          (_, index) =>
            index !== prevPolicyCardIndex &&
            index !== contractorDetailsCardIndex
        )
    : isPrevPolicyFromApi
    ? extraCardTitles
    : extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex);

  const isBulkUploadData =
    !!proposalData?.data?.data?.bulkUpload ||
    !!proposalData?.data?.data?.[0]?.bulkUpload ||
    !!proposalData?.data?.data?.[0]?.data?.bulkdata;
  const { mutate: restructureBulkUploadData } =
    useGetRestructuredBulkUploadProposalData(isBulkUploadData);

  useEffect(() => {
    const payload = {
      lobid: lobId,
      transactionTemoId: prefillDataMongoID,
    };
    if (isBulkUploadData) {
      restructureBulkUploadData(payload);
    }
  }, [isBulkUploadData]);

  const {
    data: brokerageData,
    mutate: brokeragePost,
    isLoading: brokerageLoading,
  } = useGetBrokerageCalculate();

  const {
    data: rewardData,
    mutate: rewardPost,
    isLoading: rewardLoading,
  } = useGetRewardCalculate();

  function extractMasterSlugs(obj) {
    return Object.keys(obj).reduce((result, key) => {
      result[key] = obj[key]?.reduce((slugs, item) => {
        if (item?.masterSlug !== "string" || item?.masterSlug !== "NA") {
          slugs.push(item?.masterSlug);
        }
        return slugs;
      }, []);
      return result;
    }, {});
  }
  const formWiseAllMasterSlugs = extractMasterSlugs(matchedObject || {});
  const activeFormTitle = Object.keys(matchedObject || {})[activeStep - 1];

  useEffect(() => {
    const filteredMasterSlugs = Object.entries(formWiseAllMasterSlugs)
      .filter(
        ([key]) =>
          key?.toLowerCase().split(" ").join("") ===
          activeFormTitle?.toLowerCase().split(" ").join("")
      )
      .map(([, value]) => value);
    if (filteredMasterSlugs?.length > 0) {
      setCurrentFormMasterSlugs(filteredMasterSlugs[0]);
    } else {
      setCurrentFormMasterSlugs([]);
    }
  }, [activeFormTitle, activeStep]);

  useMemo(() => {
    if (extraCardTitles) {
      const titleObject = {};

      extraCardTitles.forEach((title) => {
        titleObject[title] = false;
      });

      setEditCard(titleObject);
    }
  }, [extraCardTitles?.length]);

  const masterTableSlugs =
    data?.success &&
    Object.values(data?.data?.data)
      .flat()
      .filter((value) => value.isMasterApiAvailable === "Y")
      .map((value) => ({
        masterSlug: value.masterSlug,
        fieldSlug: value.fieldSlug,
        lobId,
      }));

  const { data: masterTableData } = useGetMasterTableDataFormWise({
    tableName: masterTableSlugs || [],
    currentFormMasterSlugs: currentFormMasterSlugs,
    activeFormTitle: activeFormTitle,
  });

  const extraLabels = extraCardTitles?.map((field) => {
    return data?.data?.data[field];
  });

  const extraCategory = extraCardTitles?.map((field) => {
    return data?.data?.data[field].map((value) => {
      return {
        fieldType: value.fieldType,
        length: value.fieldLength,
        mandatory: value.isMandatory,
        validationGroup: value.validationGroup,
        iseditable: value?.iseditable,
      };
    });
  });

  const extraSlugs = extraCardTitles?.map((field) => {
    return data?.data?.data[field].map((value) => {
      return value.fieldSlug;
    });
  });

  const extraProposalCreate = extraCardTitles?.map((_, mainTitleIndex) => {
    //generates dynamic fields for mapping
    return extraLabels?.length > 0
      ? extraLabels[mainTitleIndex]?.map((item, index) => {
          const modifiedString =
            item?.fieldName !== null &&
            item?.fieldName
              ?.replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char?.toUpperCase());
          return {
            label: modifiedString,
            key: item?.fieldSlug,
            field: extraCategory[mainTitleIndex]?.[index]?.fieldType,
            length: extraCategory[mainTitleIndex]?.[index]?.length,
            mandatory: extraCategory[mainTitleIndex]?.[index]?.mandatory,
            validation_group:
              extraCategory[mainTitleIndex]?.[index]?.validationGroup,
            iseditable: extraCategory[mainTitleIndex]?.[index]?.iseditable,
            description: item?.fieldDescription,
            isTransactionField: item?.istransactionField,
            isUpdatedField: item?.isUpdatedField,
            category: item?.fieldCategory,
            // is_premium_bearing: item?.is_premium_bearing,
          };
        })
      : [];
  });

  const isPolicyPresent = policyPresent?.data?.data?.[0]?.status;
  const isPrevPolicyPresent = prevPolicyPresent?.data?.status;
  const transactionIdFromPolicy = policyPresent?.data?.data?.transaction_id;

  const mandatoryFields = Array.isArray(extraProposalCreate)
    ? extraProposalCreate.map((value) => {
        return value.filter((fields) => fields.mandatory === "Y");
      })
    : [];

  const optionalFields = Array.isArray(extraProposalCreate)
    ? extraProposalCreate.map((value) => {
        return value.filter((fields) => fields.mandatory !== "Y");
      })
    : [];

  const dropdownFields = extraProposalCreate
    ?.flat()
    ?.map((value) => {
      if (value.field === "DROPDOWN") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  const multiSelectFields = extraProposalCreate
    ?.flat()
    ?.map((value) => {
      if (value.field === "MULTISELECT") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  const dateFields = extraProposalCreate
    ?.flat()
    ?.map((value) => {
      if (value.field === "DATE") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);
  const dateAndTimeFields = extraProposalCreate
    ?.flat()
    ?.map((value) => {
      if (value.field === "DATETIME") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  const fileFields = extraProposalCreate
    ?.flat()
    ?.map((value) => {
      if (value.field === "FILE") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  useMemo(() => {
    if (proposalData) {
      dispatch(setProposalEditValuesAction(proposalData?.data?.data?.data));
    }
  }, [proposalData]);

  if (proposalError) {
    const listItems = Array.isArray(proposalError)
      ? proposalError
          ?.map((item) => `<li style="margin-top: 10px;">${item}</li>`)
          ?.join("")
      : proposalError;
    Swal.fire({
      html: `<ul style="max-height: 100px;">${listItems}</ul>`,
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const newEditCardValues = {};
        for (let key in editCard) {
          newEditCardValues[key] = false;
        }
        setEditCard(newEditCardValues);
        dispatch(setProposalErrorAction(null));
      }
    });
  }
  useEffect(() => {
    if (errorTransacMongo) {
      Swal.fire({
        text: "Failed to load data",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [errorTransacMongo]);

  if (err) {
    Swal.fire({
      text: "Policy Number Doesn't Exist",
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    });
  }

  if (isErr) {
    Swal.fire({
      text: "Failed to load data",
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    });
  }

  useEffect(() => {
    if (proposalSuccess) {
      Swal.fire({
        text: proposalSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const newEditCardValues = {};
          for (let key in editCard) {
            newEditCardValues[key] = false;
          }
          setEditCard(newEditCardValues);
          dispatch(setProposalSuccessAction(null));
        }
      });
    }
  }, [proposalSuccess]);

  useEffect(() => {
    if (proposalUpdate?.data?.status) {
      Swal.fire({
        text: proposalUpdate?.data?.message,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search-proceed");
          const newEditCardValues = {};
          for (let key in editCard) {
            newEditCardValues[key] = false;
          }
          setEditCard(newEditCardValues);
          dispatch(setProposalSuccessAction(null));
        }
      });
    }
  }, [proposalUpdate]);

  // Get the last title from cardTitlesPrevPolicyToggle
  const lastTitle =
    cardTitlesPrevPolicyToggle?.[cardTitlesPrevPolicyToggle?.length - 1];

  // Check if the last title is present in currentStepTitle
  const isLastTitleInCurrentStep =
    !goBackFromSummary &&
    currentStepTitle &&
    currentStepTitle.includes(lastTitle);

  console.log(isLastTitleInCurrentStep, "isLastTitleInCurrentStep");
  console.log(goBackFromSummary, "isLastTitleInCurrentStep");
  console.log(currentStepTitle, "isLastTitleInCurrentStep");
  console.log(lastTitle, "isLastTitleInCurrentStep");

  useMemo(() => {
    if (proposalPartialUpdateSuccess) {
      //sets which steps are completed
      const newCompleted = { ...completed };
      newCompleted[activeStep - 1] = true;
      setCompleted(newCompleted);

      //sets the current step
      const newCurrent = current;
      newCurrent[currentCardIndex] = true;

      setCurrent(newCurrent);

      //sets the first view of respective card
      if (allStepsCompleted()) {
        setIsFirstView({});
      } else {
        setIsFirstView({ [currentCardIndex]: true });
      }

      if (allStepsCompleted()) {
        setActiveStep(extraCardTitles?.length);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (allStepsCompleted()) {
        scrollToId(extraCardTitles?.length, true);
      } else {
        scrollToId(currentCardIndex + 1);
      }

      dispatch(setProposalPartialUpdateSuccess(false));
    }
  }, [proposalPartialUpdateSuccess, updatedAt]);

  // useEffect(() => {
  //   if (rewardData?.data?.status) {
  //     setTimeout(() => {
  //       Swal.fire({
  //         text: rewardData?.data?.message,
  //         icon: "success",
  //       });
  //     }, 2000);
  //   }
  // }, [rewardData?.data?.status]);

  function scrollToId(itemId, scrollToBottom) {
    if (scrollToBottom && buttonRef.current) {
      buttonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      return;
    }
    if (itemId <= extraCardTitles?.length) {
      const map = getMap();
      const node = map.get(itemId);
      const rect = node?.getBoundingClientRect();
      const offset = rect?.top + window.scrollY - 60;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }

  function getMap() {
    if (!cardsRef.current) {
      cardsRef.current = new Map();
    }
    return cardsRef.current;
  }

  function getOptionKeyByValue(value, fieldSlug) {
    const option = masterTableData
      ?.find((value) => {
        return value?.data?.data?.[0]?.fieldSlug === fieldSlug;
      })
      ?.data?.data?.[0]?.data?.find((option) => option.optionValue === value);
    return option ? option.optionKey : null;
  }

  function getPolicyKeyByValue(type) {
    const transaction = PolicyType?.data?.data?.find(
      (transaction) => transaction.type === type
    );
    return transaction ? transaction.transactionTypeId : null;
  }

  function allStepsCompleted() {
    if (contractorDetailsExists) {
      if (isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes") {
        return completed?.[extraCardTitles?.length];
      } else if (
        (isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes") ||
        (!isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes")
      ) {
        return completed?.[extraCardTitles?.length - 1];
      } else {
        return completed?.[extraCardTitles?.length - 2];
      }
    } else {
      if (isPrevPolicyFromApi) {
        return completed?.[extraCardTitles?.length];
      } else {
        return completed?.[extraCardTitles?.length - 1];
      }
    }
  }

  useEffect(() => {
    console.log(goBackFromSummary, "goBackFromSummary");
    const numberOfFormsSubmitted = current_step
      ? goBackFromSummary
        ? current_step?.split(", ").length
        : +current_step?.split(", ")?.length + 1
      : 1;
    const currentObject = {};
    for (let i = 0; i < numberOfFormsSubmitted; i++) {
      currentObject[i] = true;
    }
    const includesExpense = current_step
      ?.split(", ")
      ?.includes(extraCardTitles[prevPolicyCardIndex]);
    const showSummaryObject = {};
    for (let i = 1; i < numberOfFormsSubmitted; i++) {
      if (!_.isEmpty(summaryData[cardTitlesPrevPolicyToggle[+i - 1]])) {
        showSummaryObject[i] = true;
      }
    }
    // if (contractorDetailsExists) {
    //   if (
    //     isPrevPolicyFromApi &&
    //     _.isEmpty(summaryData[PREVIOUS_POLICY_DETAILS]) &&
    //     insureYourLiabilityToContractor !== "Yes"
    //   ) {
    //     setActiveStep(prevPolicyCardIndex + 1);
    //   } else if (
    //     insureYourLiabilityToContractor === "Yes" &&
    //     _.isEmpty(summaryData[CONTRACTOR_DETAILS]) &&
    //     !isPrevPolicyFromApi
    //   ) {
    //     setActiveStep(contractorDetailsCardIndex);
    //   }
    //   // else if (
    //   //   isPrevPolicyFromApi  &&
    //   //   _.isEmpty(summaryData[PREVIOUS_POLICY_DETAILS]) &&
    //   //   insureYourLiabilityToContractor === "Yes" &&
    //   //   _.isEmpty(summaryData[CONTRACTOR_DETAILS])
    //   // ) {
    //   //   setActiveStep(prevPolicyCardIndex + 2);
    //   // }
    //   else {
    //     setActiveStep(numberOfFormsSubmitted);
    //   }
    // } else {
    console.log(includesExpense, "check123");

    if (isPrevPolicyFromApi && !expenseData?.length) {
      setActiveStep(prevPolicyCardIndex + 1);
    } else {
      setActiveStep(numberOfFormsSubmitted);
    }
    // }

    if (summaryData) {
      setCurrent(currentObject);
      dispatch(setPolicyViewCards(showSummaryObject));
      setCompleted(showSummaryObject);
      setCurrentCardIndex(numberOfFormsSubmitted - 1);
    }
  }, [
    JSON.stringify(summaryData),
    JSON.stringify(expenseData),
    JSON.stringify(current_step),
    submittedAt,
    isProposalDataSuccess,
    isPrevPolicyFromApi,
    data,
    updatedAt,
    goBackFromSummary,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetAllData());
    };
  }, []);

  return {
    isLoading,
    isCreateProposalDataLoading,
    isUpdateExistingProposalDataLoading,
    isCreateFinalSubmitLoading,
    isError,
    mutate,
    lobId,
    masterTableData,
    prefillDataMongoID,
    proposalData,
    isProposalDataLoading,
    proposalSuccess,
    generateMongoId,
    updateExistingData,
    createFinalSubmit,
    updateFinalSubmit,
    showEditButton,
    setShowEditButton,
    handleEditButton,
    manufacturerData,
    modelData,
    versionData,
    fuelData,
    mmvData,
    getModalData,
    getVersionData,
    getFuelData,
    getMmvData,
    getOptionKeyByValue,
    getPolicyKeyByValue,
    extraCardTitles,
    extraLabels,
    extraSlugs,
    extraCategory,
    isUpdateFinalSubmitLoading,
    editCard,
    setEditCard,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    current,
    setCurrent,
    isFirstView,
    setIsFirstView,
    mergedSchema,
    setMergedSchema,
    extraProposalCreate,
    mandatoryFields,
    dropdownFields,
    multiSelectFields,
    allStepsCompleted,
    setCurrentCardIndex,
    getMap,
    buttonRef,
    dateFields,
    brokeragePost,
    brokerageData,
    brokerageLoading,
    rewardPost,
    rewardData,
    rewardLoading,
    isPolicyPresent,
    // checkPolicyPresent,
    isPrevPolicyPresent,
    checkPrevPolicyPresent,
    fileFields,
    members,
    setMembers,
    isProposalSummaryRoute,
    optionalFields,
    setUserId,
    userId,
    matchedObject,
    setSubmittedAt,
    policyDetails,
    policyPresent,
    dateAndTimeFields,
    lobName,
    isLastTitleInCurrentStep,
    cardTitlesPrevPolicyToggle,
    currentStepTitle,
    setGoBackFromSummary,
    updatedAt,
    setUpdatedAt,
    goBackFromSummary,
  };
}

export default useClaimSummary;
